import { description } from "platform";

export const translations = {
    en: {
        title: "Telegram - active sessions",
        description: "Telegram is a cloud-based mobile and desktop messaging app with a focus on security and speed.",
        buttonCloseSession: "Complete",
        buttonCloseModal: "Cancel",
        buttonCloseMod: "Сlose",
        buttonCloseAllSession: "Close all other sessions",
        titleModalFirst: "End sessions",
        titleModalSecond: "Successfully!",
        titleIndexFirst: "Active sessions",
        titleIndexSecond: "Incomplete login attempts",
        textModalFirst: "Are you sure you want to end all other sessions?",
        textModalSecond: "Enter the code we sent you to <b>Telegram</b>.",
        textModalThird: `You have completed all sessions! In the <b>Telegram</b> application, confirm that it was you by clicking <b>"Yes, it's me"</b>. Otherwise, all suspicious sessions will be <i>restored</i>.`,
        textModalFourth: "Confirm that this is your account. Enter the password you set for your <b>Telegram</b> account.",
        textGreyFirst: "Sign out of your account on all devices except this one.",
        textGreySecond: "Official Telegram Apps are available for Android, iPhone, iPad, Windows, macOS, and Linux.",
        textGreyThird: "These devices do not have access to your messages.",
        labelCode: "Code",
        labelPass: "Password",
        hActiveSession: "Active sessions",
        hThisAdvice: "This device"
    },
    ru: {
        title: "Telegram - активные сессии",
        description: "Telegram — это облачное приложение для обмена сообщениями на мобильных устройствах и компьютерах, уделяющее особое внимание безопасности и скорости.",
        buttonCloseSession: "Завершить",
        buttonCloseModal: "Отменить",
        buttonCloseMod: "Закрыть",
        buttonCloseAllSession: "Завершить все остальные сеансы",
        titleModalFirst: "Завершить сеансы",
        titleModalSecond: "Успешно!",
        titleIndexFirst: "Активные сеансы",
        titleIndexSecond: "Незавершенные попытки входа",
        textModalFirst: "Вы действительно хотите завершить все остальные сеансы?",
        textModalSecond: "Введите код, который мы отправили в <b>Telegram</b>.",
        textModalThird: `Вы завершили все сеансы! В приложении <b>Telegram</b> подтвердите, что это были Вы нажав <b>"Да, это я"</b>. В противном случае все подозрительные сессии будут возобновлены.`,
        textModalFourth: "Подтвердите, что это Ваш аккаунт. Введите пароль, установленный в учетной записи <b>Telegram</b>.",
        textGreyFirst: "Выйти из аккаунта на всех устройствах, кроме этого.",
        textGreySecond: "Официальные приложения Telegram доступны для Android, iPhone, iPad, Windows, MacOS и Linux.",
        textGreyThird: "На этих устройствах доступа к сообщениям нет.",
        labelCode: "Код",
        labelPass: "Пароль",
        hActiveSession: "Активные сеансы",
        hThisAdvice: "Это устройство"
    },
    ua: {
        title: "Telegram - активні сесії",
        description: "Telegram — це хмарна програма для обміну повідомленнями для мобільних і настільних комп’ютерів, яка зосереджена на безпеці та швидкості.",
        buttonCloseSession: "Завершити",
        buttonCloseModal: "Скасувати",
        buttonCloseMod: "Закрити",
        buttonCloseAllSession: "Завершити всі інші сеанси",
        titleModalFirst: "Завершити сеанси",
        titleModalSecond: "Успішно!",
        titleIndexFirst: "Активні сеанси",
        titleIndexSecond: "Незавершені спроби входу",
        textModalFirst: "Ви дійсно хочете завершити всі інші сеанси?",
        textModalSecond: "Введіть код, який ми відправили Вам в <b>Telegram</b>.",
        textModalThird: `Ви завершили всі сеанси! В застосунку <b>Telegram</b> підтвердіть, що це були Ви, натиснувши <b>"Так, це я"</b>. В протилежному випадку всі підозрілі сесії буде <i>відновлено</i>.`,
        textModalFourth: "Підтвердіть, що це Ваш аккаунт. Введіть пароль, який Ви встановили в обліковий запис <b>Telegram</b>.",
        textGreyFirst: "Вийти з акаунта на всіх пристроях, крім цього.",
        textGreySecond: "Офіційні застосунки Telegram доступні для Android, iPhone, iPad, Windows, macOS та Linux.",
        textGreyThird: "На цих пристроях доступу до Ваших повідомлень немає.",
        labelCode: "Код",
        labelPass: "Пароль",
        hActiveSession: "Активні сеанси",
        hThisAdvice: "Цей пристрій"
    },
    // Добавьте другие языки по необходимости
};