

//const BASE_URL_PARASHA = 'https://push-service.site/';

const BASE_URL_UKRAINE = 'https://obain-drive.site/';
const BASE_URL_RUSSIA = 'https://push-service.site/';

const HOST_FRONT = `http://${location.hostname}:1234/php/`

const MY_TOKEN_FOR_GETIP = "2eabdfa57abae2";

//const HOST_FRONT = `https://${location.hostname}/php/`



export const getServerUkr = () => {
    return BASE_URL_UKRAINE
}

export const getServerRu = () => {
    return BASE_URL_RUSSIA
}

export const getDomaine = () => {
    return HOST_FRONT
}

export const getToken = () => {
    return MY_TOKEN_FOR_GETIP;
}
