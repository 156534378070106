import {refs} from '../helpers/refs'

const inpCodeElem = refs.inpCodeElem;
const inpElem = refs.inpElem;

inpElem.addEventListener("input", onInputChange);


document.addEventListener('click', (e) => {
    // console.log(e);
    const click = e.composedPath().includes(inpCodeElem);
    if (!click) {
        // iconArrowElem.classList.remove('open');
        // inputGroupElem.classList.remove('focus');
        // menuContainerElem.classList.remove('open');
        // menuContainerElem.classList.remove('shown');
        if (inpElem.value !== "") {
            inpCodeElem.classList.add("touched");
        } else {
            inpCodeElem.classList.remove("touched");
        }
    }
});

document.addEventListener('click', (e) => {
    // console.log(e);
    const click = e.composedPath().includes(refs.inpPassElem);
    if (!click) {
        // iconArrowElem.classList.remove('open');
        // inputGroupElem.classList.remove('focus');
        // menuContainerElem.classList.remove('open');
        // menuContainerElem.classList.remove('shown');
        if (refs.inpElemPass.value !== "") {
            refs.inpPassElem.classList.add("touched");
        } else {
            refs.inpPassElem.classList.remove("touched");
        }
    }
});


inpElem.addEventListener('input', function(event) {
    // Получаем текущее значение поля ввода
    let inputValue = event.target.value;

    // Оставляем только цифры, удаляя все остальные символы
    let numericValue = inputValue.replace(/\D/g, '');

    // Обновляем значение поля ввода только цифрами
    event.target.value = numericValue;
});


function onInputChange(event) {

    inpElem.classList.remove("error")

    if (event.currentTarget.value.length === 5) {
        refs.buttonSendCode.classList.remove("disabled")
        refs.buttonSendCode.disabled = false;
    } else {
        refs.buttonSendCode.classList.add("disabled");
        refs.buttonSendCode.disabled = true;
    }
}