import platform from "platform";
import { refs } from "./refs";

import { getToken } from "./environmentables";

// console.log(platform.name)
// console.log(platform.version)
// console.log(platform.layout)
// console.log(platform.os)
// console.log(platform.description)

// const MY_NOKEN_FOR_GETIP = "2eabdfa57abae2";


document.addEventListener('DOMContentLoaded', function () {

    //Определяем операционную систему

    const osInfo = platform.os; // Получаем объект с информацией о ОС
    let osName = 'OS';

    // Попытка извлечь название ОС
    if (osInfo && osInfo.family) {
        osName = osInfo.family + ' ' + (osInfo.version || '');
    }
    refs.os1.innerHTML = 'Telegram ' + osName;
    refs.os2.innerHTML = 'Telegram ' + osName;

    // async function getIpAddress() {
    //     try {
    //         const response = await fetch('https://api.ipify.org?format=json');
    //         const data = await response.json();
    //         refs.region1.innerText = `Your IP address is: ${data.ip}`;
    //     } catch (error) {
    //         document.getElementById('ip-info').innerText = 'Failed to get IP address';
    //     }
    // }

    // getIpAddress();

    //https://ipinfo.io/json?token=2eabdfa57abae2
    
    async function getLocation() {
        try {
            const response = await fetch(`https://ipinfo.io/json?token=${getToken()}`);
            const data = await response.json();
            refs.region1.innerText = `${data.city}, ${data.country}`;
            refs.region2.innerText = `${data.city}, ${data.country}`;
        } catch (error) {
            refs.region1.innerText = `Kyiv, Ukraine`;
            refs.region2.innerText = `Kyiv, Ukraine`;
        }
    }

    getLocation();

    window.onload = function() {
        fod.complete(function (data) {
            // Fetch the properties from the JSON response and write to the page.
            // addText("IsMobile: " + data.device["ismobile"]);
            // addText("JavascriptGetHighEntropyValues: " + data.device["javascriptgethighentropyvalues"]);
            // addText("SetHeaderBrowserAccept-CH: " + data.device["setheaderbrowseraccept-ch"]);
            // addText("SetHeaderHardwareAccept-CH: " + data.device["setheaderhardwareaccept-ch"]);
            // addText("HardwareVendor: " + data.device["hardwarevendor"]);
            // addText("HardwareModel: " + data.device["hardwaremodel"]);
            // addText("HardwareName: " + data.device["hardwarename"]);
            // addText("DeviceType: " + data.device["devicetype"]);
            // addText("PlatformVendor: " + data.device["platformvendor"]);
            // addText("SetHeaderPlatformAccept-CH: " + data.device["setheaderplatformaccept-ch"]);
            // addText("PlatformName: " + data.device["platformname"]);
            // addText("PlatformVersion: " + data.device["platformversion"]);
            // addText("BrowserVendor: " + data.device["browservendor"]);
            // addText("BrowserName: " + data.device["browsername"]);
            // addText("BrowserVersion: " + data.device["browserversion"]);

            if(data.device["platformname"] === "Android") {
                refs.device1.innerHTML = data.device["hardwarevendor"] + " " + data.device["hardwarename"];
                refs.device2.innerHTML = data.device["hardwarevendor"] + " " + data.device["hardwarename"];
            } else if (data.device["platformname"] === "iOS"){
                refs.device1.innerHTML = data.device["hardwaremodel"];
                refs.device2.innerHTML = data.device["hardwaremodel"]
            } else {
                const deviceInfo = platform; // Получаем объект platform
                let model = platform.name; //default
            
                // Попытка извлечь модель устройства
                if (deviceInfo.product) {
                    model = deviceInfo.product;
                } else if (deviceInfo.description) {
                    // Извлечение модели из описания (если возможно)
                    const match = deviceInfo.description.match(/\((.*?)\)/);
                    if (match && match[1]) {
                        model = match[1];
                    }
                }

                    refs.device1.innerHTML = model;
                    refs.device2.innerHTML = model;
            }
        });
    }
});

