import { refs } from "../helpers/refs";

let  spinnerContainer = refs.spinerContainer;

// Функция для отображения спиннера
export function showSpinner() {
    if (spinnerContainer) {
        spinnerContainer.style.visibility = 'visible';
        document.body.style.overflow = 'hidden'; // Запрет прокрутки заднего фона
    }
}

// Функция для скрытия спиннера
export function hideSpinner() {
    if (spinnerContainer) {
        spinnerContainer.style.visibility = 'hidden';
        document.body.style.overflow = 'auto'; // Восстановление прокрутки заднего фона
    }
}

// Здесь можно добавить асинхронные операции, которые требуют загрузку данных или обработку

// Пример задержки для демонстрации
// setTimeout(function() {
//     hideSpinner();
// }, 3000);


// Скрыть спиннер через 3 секунды (для примера)

// Заморозка области при запуске