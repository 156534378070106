import { translations } from "./dataLanguage";

export const getLanguage = () => {
    const userLang = navigator.language || navigator.userLanguage;

    // Установите язык на основе языка браузера
    let defaultLang = "";
    if (userLang === "uk-UA" || userLang === "uk"){
        defaultLang = "ua";
    } else if (userLang === "ru-RU" || userLang === "ru") {
        defaultLang = "ru";
    } else if (userLang === "en-EN" || userLang === "en"){
        defaultLang = "en";
    }else {defaultLang = "en";}

    return defaultLang;
}

document.addEventListener("DOMContentLoaded", function() {
    // Установите язык по умолчанию

    const lang = getLanguage();

    setLanguage(lang);
});

function setLanguage(lang) {
    const elements = document.querySelectorAll("[data-translate]");
    elements.forEach(element => {
        const key = element.getAttribute("data-translate");
        if (translations[lang] && translations[lang][key]) {
            element.innerHTML = translations[lang][key];
        }
    });
}