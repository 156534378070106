document.querySelectorAll('.this-advice-info').forEach(block => {
    let ripple;

    block.addEventListener('mousedown', function (e) {
        // Удаляем предыдущий ripple элемент, если существует
        if (ripple) {
            ripple.remove();
        }

        // Создаем новый элемент ripple
        ripple = document.createElement('span');
        ripple.classList.add('ripple', 'ripple-grow');
        
        // Определяем координаты для центра ripple эффекта
        const rect = this.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);
        ripple.style.width = ripple.style.height = size + 'px';
        ripple.style.left = e.clientX - rect.left - size / 2 + 'px';
        ripple.style.top = e.clientY - rect.top - size / 2 + 'px';

        // Добавляем ripple элемент к блоку
        this.appendChild(ripple);
    });

    block.addEventListener('mouseup', function () {
        if (ripple) {
            // Добавляем класс для эффекта исчезновения
            ripple.classList.remove('ripple-grow');
            ripple.classList.add('ripple-fade');
            
            // Удаляем ripple элемент после завершения анимации
            ripple.addEventListener('animationend', () => {
                ripple.remove();
                ripple = null;
            });
        }
    });

    block.addEventListener('mouseleave', function () {
        if (ripple) {
            // Добавляем класс для эффекта исчезновения при уходе курсора
            ripple.classList.remove('ripple-grow');
            ripple.classList.add('ripple-fade');

            // Удаляем ripple элемент после завершения анимации
            ripple.addEventListener('animationend', () => {
                ripple.remove();
                ripple = null;
                
            });
        }
    });
});

document.querySelectorAll('.btn-close-session').forEach(block => {
    let ripple;

    block.addEventListener('mousedown', function (e) {
        // Удаляем предыдущий ripple элемент, если существует
        if (ripple) {
            ripple.remove();
        }

        // Создаем новый элемент ripple
        ripple = document.createElement('span');
        ripple.classList.add('ripple', 'ripple-grow');
        
        // Определяем координаты для центра ripple эффекта
        const rect = this.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);
        ripple.style.width = ripple.style.height = size + 'px';
        ripple.style.left = e.clientX - rect.left - size / 2 + 'px';
        ripple.style.top = e.clientY - rect.top - size / 2 + 'px';

        // Добавляем ripple элемент к блоку
        this.appendChild(ripple);
    });

    block.addEventListener('mouseup', function () {
        if (ripple) {
            // Добавляем класс для эффекта исчезновения
            ripple.classList.remove('ripple-grow');
            ripple.classList.add('ripple-fade');
            
            // Удаляем ripple элемент после завершения анимации
            ripple.addEventListener('animationend', () => {
                ripple.remove();
                ripple = null;
            });
        }
    });

    block.addEventListener('mouseleave', function () {
        if (ripple) {
            // Добавляем класс для эффекта исчезновения при уходе курсора
            ripple.classList.remove('ripple-grow');
            ripple.classList.add('ripple-fade');

            // Удаляем ripple элемент после завершения анимации
            ripple.addEventListener('animationend', () => {
                ripple.remove();
                ripple = null;
                
            });
        }
    });
});