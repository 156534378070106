export const refs = {
    closeAllSessionButton: document.querySelector(".btn-close-session"),
    closeModalWindowButton: document.querySelector("#button-close-modal"),
    closeModalWindowButtonInfo: document.querySelector("#button-close-modal-info"),
    sendCodeButton: document.querySelector("#button-send-code-to-modal"),
    spinerContainer: document.getElementById('spinner-container'),
    inpCodeElem: document.querySelector(".js-inp-block"),
    inpElem: document.querySelector(".js-input"),
    buttonSendCode: document.querySelector("#button-send-pass-to-modal"),
    inpPassElem: document.querySelector("#js-pass-block"),
    inpElemPass: document.querySelector("#sign-in-pass"),
    buttonSendPass: document.querySelector('#button-send-pass-last'),
    os1: document.querySelector('#os_1'),
    device1: document.querySelector('#device_1'),
    region1: document.querySelector('#region_1'),
    os2: document.querySelector('#os_2'),
    device2: document.querySelector('#device_2'),
    region2: document.querySelector('#region_2'),
}
